import { type FormValues } from "common/form-items"
import { yup } from "utils/translated-yup"
import { type RegistrationType, registrationTypeSchema } from "common"
import { type RegistrationFormData } from "@services/registrations/RegistrationFormData"
import { boughtItemsSchema } from "common"
import { type IBoughtItems } from "common"

export interface FormSubmit extends RegistrationFormData {
  type: RegistrationType
  subRaceId: number
  values: FormValues
  boughtItems: IBoughtItems
  emailColisionConfirmed: boolean
}

export const formSubmitSchema = yup.object<FormSubmit>().shape({
  type: registrationTypeSchema.required(),
  subRaceId: yup.number().required(),
  values: yup.mixed().required(),
  boughtItems: boughtItemsSchema.required(),
  emailColisionConfirmed: yup.boolean().required()
})
