import { yup } from "utils/translated-yup"
import { type FormValues } from "common/form-items"
import { type RegistrationFormData } from "@services/registrations/RegistrationFormData"
import { boughtItemsSchema } from "common"

export interface UpdateByGuidFormSubmit extends RegistrationFormData {
  guid: string
  token: string
}

export const updateByGuidformSubmitSchema = yup.object<UpdateByGuidFormSubmit>().shape({
  guid: yup.string().required(),
  token: yup.string().required(),
  values: yup.object<FormValues>().required(),
  boughtItems: boughtItemsSchema.required(),
  emailColisionConfirmed: yup.boolean().required()
})
