import { isClientSide } from "utils"
import { type Ctx } from "@jaredpalmer/after"
import { getClientLogger } from "@infrastructure/client-logger/clientLogger"

interface StatusResult {
  statusCode: number
  redirectTo?: string
}

export const initialPropsWrapper = <T>(handler: (props: Ctx<any>) => Promise<T | StatusResult>): ((props: Ctx<any>) => Promise<T | StatusResult>) => {
  return async (props: Ctx<any>) => {
    try {
      return await handler(props)
    } catch (e) {
      if (isClientSide()) {
        getClientLogger().error("Error in getting initial props", e as Error)
      }
      throw e
    }
  }
}
