import ContentLoader from "react-content-loader"
import Container from "./Container/Container"

import { Delayed } from "./Delayed"

export const PageContentLoader = () => (
  <Container>
    <Delayed delay={500} condition={true}>
      <ContentLoader animate={true} width={400} height={400}>
        <rect x="5" y="5" rx="10" ry="10" width="180" height="120" />
        <rect x="200" y="5" rx="4" ry="4" width="100" height="15" />
        <rect x="200" y="32" rx="4" ry="4" width="125" height="15" />
        <rect x="200" y="57" rx="4" ry="4" width="85" height="15" />
        <rect x="200" y="85" rx="4" ry="4" width="65" height="15" />
        <rect x="200" y="110" rx="4" ry="4" width="100" height="15" />
        <rect x="5" y="150" rx="4" ry="4" width="180" height="60" />
        <rect x="200" y="150" rx="4" ry="4" width="180" height="60" />
        <rect x="5" y="225" rx="4" ry="4" width="121" height="15" />
        <rect x="5" y="250" rx="4" ry="4" width="90" height="15" />
        <rect x="125" y="250" rx="4" ry="4" width="55" height="15" />
        <rect x="155.5" y="225" rx="4" ry="4" width="26" height="15" />
        <rect x="200" y="225" rx="4" ry="4" width="43" height="15" />
        <rect x="285" y="225" rx="4" ry="4" width="95" height="15" />
        <rect x="200" y="250" rx="4" ry="4" width="109" height="15" />
        <rect x="350" y="250" rx="4" ry="4" width="30" height="15" />
      </ContentLoader>
    </Delayed>
  </Container>
)
