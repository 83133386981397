/* eslint-disable no-console */
interface ClientLogger {
  debug: (...args: (string | Error)[]) => void
  info: (...args: (string | Error)[]) => void
  warning: (...args: (string | Error)[]) => void
  error: (...args: (string | Error)[]) => void

  log: (...args: (string | Error)[]) => void
}

export const getClientLogger = (): ClientLogger => {
  if (window.rollbar) {
    return window.rollbar
  }

  return {
    debug: console.debug,
    info: console.info,
    warning: console.warn,
    error: console.error,

    log: console.log
  }
}
