import { yup } from "utils/translated-yup"

export interface SendInfoRequest {
  email: string
}

export const sendInfoRequestSchema = yup.object<SendInfoRequest>().shape({
  email: yup.string().email().required().label("Email")
})

export interface SendInfoResponse {
  status: "OK" | "NotFound" | "FailedToSend" | "AlreadySent" | "CommonError"
  email?: string
}
