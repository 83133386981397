import { type AnyJson } from "../types/jsonType"

export class Success {
  readonly ok: string = "OK"

  static fromJson(json: AnyJson): Success {
    const instance = Object.create(Success.prototype)
    return Object.assign(instance, json)
  }
}

export function hasFormErrors<MODEL>(res: any): res is FormErrors<MODEL> {
  return (res as Errors).isValid !== undefined
}

export const getResultOr = <RES>(res: Errors | RES, defaultValue: RES): RES => {
  if (hasFormErrors(res)) {
    return defaultValue
  }
  return res as RES
}

export interface Errors {
  readonly formError?: string
  readonly fieldErrors?: { [k: string]: string }
  isValid(): boolean
}

export class FormErrors<REQ> implements Errors {
  static fromJson<REQ>(json: AnyJson): FormErrors<REQ> {
    const instance = Object.create(FormErrors.prototype)
    return Object.assign(instance, json)
  }

  static for<REQ>(): FormErrors<REQ> {
    return new FormErrors<REQ>()
  }

  readonly formError?: string
  readonly fieldErrors?: { [k: string]: string }

  constructor(formError?: string, fieldErrors?: { [k: string]: string }) {
    this.formError = formError
    this.fieldErrors = fieldErrors
  }

  field(name: keyof REQ, error: string): FormErrors<REQ> {
    return new FormErrors<REQ>(this.formError, { ...this.fieldErrors, [name]: error })
  }

  form(error: string): FormErrors<REQ> {
    return new FormErrors<REQ>(error, this.fieldErrors)
  }

  isValid(): boolean {
    return !this.formError && !this.fieldErrors
  }
}

export const createFormError = <REQ>(error: string): FormErrors<REQ> => {
  return FormErrors.for<REQ>().form(error)
}
