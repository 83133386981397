import axios, { type AxiosRequestConfig, type AxiosResponse } from "axios"
import { type AnyJson } from "../types/jsonType"
import { type ApiCall, ApiMethod } from "./apiCall"
import { FormErrors } from "./serverErrors"
import { getServerContext } from "../afterjs/serverContext"

export enum ResponseType {
  ok,
  notFound,
  validationError,
  unauthorized,
  forbidden,
  conflict
}

export type ServerResponse<RES, ERROR> = OkResponse<RES> | NotFoundResponse | UnauthorizedResponse | ForbiddenResponse | ValidationErrorResponse<ERROR> | ConflictResponse

export interface OkResponse<RES> {
  type: ResponseType.ok
  data: RES
}

export interface NotFoundResponse {
  type: ResponseType.notFound
}

export interface ForbiddenResponse {
  type: ResponseType.forbidden
}

export interface UnauthorizedResponse {
  type: ResponseType.unauthorized
}

export interface ValidationErrorResponse<ERROR> {
  type: ResponseType.validationError
  error: ERROR
}

export interface ResultWithStatus<RES> {
  data: RES
  status: number
}

export interface ConflictResponse {
  type: ResponseType.conflict
  data: any
}

export class ServerCall {
  static async call<REQ, RES, ERROR = unknown>(
    apiCall: ApiCall<REQ>,
    requestData?: REQ,
    deserializer?: (res: AnyJson) => RES,
    errorDeserializer?: (res: AnyJson) => ERROR
  ): Promise<ServerResponse<RES, ERROR>> {
    const errDes = errorDeserializer ? errorDeserializer : (res: AnyJson) => res as any as ERROR
    return this.createResponse(apiCall, ServerCall.innerCall(apiCall, requestData, deserializer), errDes)
  }

  static async callForm<REQ, RES>(apiCall: ApiCall<REQ>, requestData?: REQ, deserializer?: (res: AnyJson) => RES): Promise<ServerResponse<RES, FormErrors<REQ>>> {
    return this.createResponse(apiCall, ServerCall.innerCall(apiCall, requestData, deserializer), res => FormErrors.fromJson<REQ>(res))
  }

  static async callOrThrow<REQ, RES>(apiCall: ApiCall<REQ>, requestData?: REQ, deserializer?: (res: AnyJson) => RES): Promise<RES> {
    const result = await this.innerCall(apiCall, requestData, deserializer)

    if (result.status !== 200) {
      throw new Error(`Unable to send/receive data, status: ${result.status}`)
    }
    return result.data
  }

  private static async innerCall<REQ, RES>(apiCall: ApiCall<REQ>, requestData?: REQ, deserializer?: (res: AnyJson) => RES): Promise<ResultWithStatus<RES>> {
    if (apiCall.method === ApiMethod.Get) {
      return this.get(apiCall.url, requestData, deserializer)
    }
    if (apiCall.method === ApiMethod.Post) {
      return this.post(apiCall.url, requestData, deserializer)
    }
    throw Error("Not supported HTTP method")
  }

  private static async get<REQ, RES>(url: string, params?: REQ, deserializer?: (res: AnyJson) => RES): Promise<ResultWithStatus<RES>> {
    return this.request(ServerCall.createfetchOptions("GET", url, params, undefined), deserializer)
  }

  private static async post<REQ, RES>(url: string, data?: REQ, deserializer?: (res: AnyJson) => RES): Promise<ResultWithStatus<RES>> {
    return this.request(ServerCall.createfetchOptions("POST", url, {}, data), deserializer)
  }

  private static async createResponse<RES, ERROR>(apiCall: ApiCall<any>, callback: Promise<ResultWithStatus<RES>>, errorDeserializer: (res: AnyJson) => ERROR): Promise<ServerResponse<RES, ERROR>> {
    const res = await callback
    if (res.status === 404) {
      return { type: ResponseType.notFound }
    }
    if (res.status === 400) {
      const deserialized = errorDeserializer(res.data as any)
      const errorReponse: ValidationErrorResponse<ERROR> = { type: ResponseType.validationError, error: deserialized }
      return errorReponse
    }
    if (res.status === 200) {
      const okResponse: OkResponse<RES> = { type: ResponseType.ok, data: res.data }
      return okResponse
    }
    if (res.status === 401) {
      const unauthorized: UnauthorizedResponse = { type: ResponseType.unauthorized }
      return unauthorized
    }
    if (res.status === 403) {
      const forbidden: ForbiddenResponse = { type: ResponseType.forbidden }
      return forbidden
    }
    if (res.status === 409) {
      return { type: ResponseType.conflict, data: res.data }
    }

    throw new Error(`Unhandled status ${res.status} in server call ${apiCall.url}`)
  }

  private static async request<RES>(config: AxiosRequestConfig, deserializer?: (res: AnyJson) => RES): Promise<ResultWithStatus<RES>> {
    const res = await axios.request<RES>(config)
    const deserialized = this.handleJsonResponse<RES>(res, deserializer)
    return { data: deserialized, status: res.status }
  }

  private static handleJsonResponse<RES>(res: AxiosResponse<RES>, deserializer?: (res: AnyJson) => RES): RES {
    return deserializer ? deserializer(res.data as any) : res.data
  }

  private static createfetchOptions(method: "GET" | "POST", url: string, params?: any, data?: any): AxiosRequestConfig {
    return {
      baseURL: getServerContext().baseUrl,
      data: data && JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        "Cache-Control": "no-cache",
        "csrf-token": getServerContext().csrfToken
      },
      method: method,
      params: params,
      withCredentials: true,
      validateStatus: status => status >= 200 && status < 500,
      url: url
    }
  }
}
