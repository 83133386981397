import type yup from "yup"

export enum ApiMethod {
  Get,
  Post
}

export interface ApiCall<REQ> {
  method: ApiMethod
  schema?: yup.Schema<REQ>
  url: string
}

export interface GetApiCall<REQ> extends ApiCall<REQ> {
  method: ApiMethod.Get
}

export interface PostApiCall<REQ> extends ApiCall<REQ> {
  method: ApiMethod.Post
}

export function get<REQ = any>(url: string, validator?: yup.Schema<REQ>): GetApiCall<REQ> {
  return {
    method: ApiMethod.Get,
    schema: validator,
    url
  }
}

export function post<REQ = any>(url: string, validator?: yup.Schema<REQ>): PostApiCall<REQ> {
  return {
    method: ApiMethod.Post,
    schema: validator,
    url
  }
}
